import { Link, Typography } from '@mui/material'
import { FlexBoxProps, FlexRow } from '@xyo-network/sdk-xyo-react-js'

const Bar: React.FC = () => {
  return (
    <Typography marginX={1} component="span">
      |
    </Typography>
  )
}

const Ampersand: React.FC = () => {
  return (
    <Typography marginX={1} component="span">
      &amp;
    </Typography>
  )
}

const Copyright: React.FC<FlexBoxProps> = (props) => (
  <FlexRow {...props}>
    <Typography margin={1} variant="subtitle1" textAlign="center">
      Copyright &copy; 2021{' '}
      <Link href="https://xylabs.com/" target="_blank">
        XY Labs, Inc.
      </Link>
      <Ampersand />
      <Link href="https://xyo.network/" target="_blank">
        XYO Foundation
      </Link>
      <Bar />
      <Link href="https://xylabs.com/privacy/" target="_blank">
        Privacy Policy
      </Link>
      <Bar />
      <Link href="https://xylabs.com/terms/" target="_blank">
        Terms of Service
      </Link>
      <Bar />
      <Link href="https://jobs.xylabs.com/" target="_blank">
        Careers
      </Link>
    </Typography>
  </FlexRow>
)

export default Copyright
