import {
  FunnelStartedFields,
  TestStartedFields,
  UserClickFields,
  UserEventHandler,
  ViewContentFields,
} from '@xylabs/pixel'

//import DripEventHandler from './DripEventHandler'
import FacebookEventHandler from './FacebookEventHandler'
import GoogleEventHandler from './GoogleEventHandler'
//import RedditEventHandler from './RedditEventHandler'
//import SnapchatEventHandler from './SnapchatEventHandler'
//import TiktokEventHandler from './TiktokEventHandler'
import CoinEventHandler from './XyEventHandler'

class UserEvents<T extends Record<string, unknown>> extends UserEventHandler<T> {
  testStarted(_fields: T | TestStartedFields): Promise<void> {
    throw new Error('Method not implemented.')
  }
  funnelStarted(_fields: T | FunnelStartedFields): Promise<void> {
    throw new Error('Method not implemented.')
  }
  protected handlers: UserEventHandler<T>[]
  private constructor(handlers?: UserEventHandler<T>[]) {
    super()
    this.handlers = handlers ?? [
      new CoinEventHandler<T>(),
      new FacebookEventHandler<T>(),
      new GoogleEventHandler<T>(),
      //new DripEventHandler<T>(),
      //new TiktokEventHandler<T>(),
      //new RedditEventHandler<T>(),
      //new SnapchatEventHandler<T>(),
    ]
  }

  async viewContent(fields: ViewContentFields) {
    await Promise.all(this.handlers.map((handler) => handler.viewContent(fields)))
  }

  async userClick(fields: UserClickFields) {
    await Promise.all(this.handlers.map((handler) => handler.userClick(fields)))
  }

  static instance: UserEvents<Record<string, unknown>>
  static get() {
    this.instance = this.instance ?? new UserEvents()
    return this.instance
  }
}

export default UserEvents
