import { GoogleCustomEvent, GoogleStandardEvents } from '@xyo-network/sdk-xyo-react-js'

export interface GoogleViewContent extends Record<string, unknown> {
  name: string
  path: string
}

export interface GoogleUserClick extends Record<string, unknown> {
  elementName: string
  elementType: string
}

class GoogleEvents<T extends Record<string, unknown>> extends GoogleStandardEvents<T> {
  public viewContent() {
    return new GoogleCustomEvent<GoogleViewContent | T>('ViewContent')
  }

  public userClick() {
    return new GoogleCustomEvent<GoogleUserClick | T>('UserClick')
  }
}

export default GoogleEvents
