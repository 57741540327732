import { CssBaseline, useTheme } from '@mui/material'
import { Fbq, FlexGrowCol, Gtag, InvertableThemeProvider } from '@xyo-network/sdk-xyo-react-js'
import { Helmet } from 'react-helmet'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'

import { PixelProvider, SettingsLoader, useSettings } from './Contexts'
import { HomePages, SettingsPages } from './Pages'
import { themeOptions } from './theme'

Fbq.init('339003476728548')
Gtag.init('G-TCJLRQCJ2H', 'AW-989965544', ['jobs.xylabs.com', 'xylabs.com', 'localhost', 'localhost:3000'])

const AppBody: React.FC = () => {
  const theme = useTheme()
  return (
    <PixelProvider id="jobs.xylabs.com">
      <Router>
        <FlexGrowCol
          width="100vw"
          minHeight="100vh"
          justifyContent="flex-start"
          alignContent="stretch"
          bgcolor={theme.palette.background.default}
          color={theme.palette.text.primary}
        >
          <Switch>
            <Route component={SettingsPages} path="/settings" />

            <Route component={HomePages} path="/" />
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </FlexGrowCol>
      </Router>
    </PixelProvider>
  )
}

const AppThemeBody: React.FC = () => {
  const { darkMode } = useSettings()
  return (
    <InvertableThemeProvider dark={darkMode} options={themeOptions}>
      <AppBody />
    </InvertableThemeProvider>
  )
}

const App: React.FC = () => {
  return (
    <>
      <CssBaseline />
      <Helmet defaultTitle="Exchanges | XYO" titleTemplate="%s | XYO">
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-TCJLRQCJ2H" />
        <script async src="https://connect.facebook.net/en_US/fbevents.js" />
      </Helmet>
      <SettingsLoader>
        <InvertableThemeProvider options={themeOptions}>
          <AppThemeBody />
        </InvertableThemeProvider>
      </SettingsLoader>
    </>
  )
}

export default App
