import { Link, Typography } from '@mui/material'
import { FlexBoxProps, FlexCol } from '@xyo-network/sdk-xyo-react-js'
import React from 'react'

const XyoTokens: React.FC<FlexBoxProps> = (props) => (
  <FlexCol {...props}>
    <Typography margin={1} variant="h6">
      XYO Tokens
    </Typography>
    <Link margin={1} href="https://exchanges.xyo.network/" variant="body1">
      Exchanges
    </Link>
    <Link margin={1} href="https://xyo.network/token/" variant="body1">
      About
    </Link>
    <Link margin={1} href="https://xyo.network/fhr/" target="_blank" variant="body1">
      FHR Benefits
    </Link>
  </FlexCol>
)

export default XyoTokens
