import { Container, Typography, useTheme } from '@mui/material'
import { ButtonEx, FlexBoxProps, FlexCol, FlexRow, useBreakpoint } from '@xyo-network/sdk-xyo-react-js'

import { CoinbaseLogo, MetaMaskLogo, TrustWalletLogo } from '../../img'
import { floorWave } from './img'
const Wallets: React.FC<FlexBoxProps> = (props) => {
  const theme = useTheme()
  const breakpoint = useBreakpoint()
  return (
    <FlexRow
      bgcolor={theme.palette.secondary.light}
      color={theme.palette.secondary.dark}
      {...props}
      sx={{
        backgroundPositionX: { xs: '-500px' },
        backgroundPositionY: { md: '500px', xs: '200px' },
        height: { md: '100vh' },
        maxHeight: { md: '1000px' },
      }}
      style={{
        backgroundImage: `url(${floorWave})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Container sx={{ marginY: { xs: 5 } }}>
        <FlexCol>
          <FlexCol alignItems="center" flexGrow={100} flexBasis={100} maxWidth="700px">
            <Typography flexWrap="wrap" align="center" variant="subtitle1" gutterBottom={true}>
              WALLETS
            </Typography>
            <Typography
              flexWrap="wrap"
              align="center"
              variant="h3"
              fontWeight={700}
              gutterBottom={true}
              paddingBottom={2}
            >
              Wallets supporting XYO Token
            </Typography>
            <Typography flexWrap="wrap" align="center" variant="body1" gutterBottom={true}>
              Most modern Ethereum Wallets support XYO Tokens
            </Typography>
          </FlexCol>
        </FlexCol>
        <FlexCol
          alignItems="flex-start"
          flexBasis={100}
          sx={{ flexDirection: { xs: 'row' } }}
          flexWrap="wrap"
          alignContent="flex-start"
          padding={3}
        >
          <ButtonEx
            size={breakpoint === 'xs' ? 'medium' : 'large'}
            margin={1}
            href="https://metamask.io/"
            target="_blank"
            style={{ backgroundColor: '#ffffff' }}
            variant="contained"
            aria-label="Metamask"
          >
            <img height={24} src={MetaMaskLogo} alt="Metamask" />
          </ButtonEx>
          <ButtonEx
            size={breakpoint === 'xs' ? 'medium' : 'large'}
            margin={1}
            href="https://wallet.coinbase.com/"
            target="_blank"
            style={{ backgroundColor: '#ffffff' }}
            variant="contained"
            aria-label="Coinbase Wallet"
          >
            <img height={24} src={CoinbaseLogo} alt="Coinbase" />
          </ButtonEx>
          <ButtonEx
            size={breakpoint === 'xs' ? 'medium' : 'large'}
            margin={1}
            href="https://trustwallet.com/"
            target="_blank"
            style={{ backgroundColor: '#ffffff' }}
            variant="contained"
            aria-label="Trust Wallet"
          >
            <img height={24} src={TrustWalletLogo} alt="Trust Wallet" />
          </ButtonEx>
        </FlexCol>
      </Container>
    </FlexRow>
  )
}

export default Wallets
