import { Container, Typography, useTheme } from '@mui/material'
import { ButtonEx, FlexBoxProps, FlexCol, FlexRow, useBreakpoint } from '@xyo-network/sdk-xyo-react-js'

import { CoingeckoLogo, CoinMarketCapLogo, CoinRankingLogo, CryptoComLogo } from '../../img'
import { curlWave, floorWave } from './img'

const Price: React.FC<FlexBoxProps> = (props) => {
  const theme = useTheme()
  const breakpoint = useBreakpoint()
  return (
    <FlexRow
      color={theme.palette.primary.dark}
      {...props}
      sx={{
        backgroundImage: { md: `url(${curlWave})`, xs: `url(${floorWave})` },
        backgroundPositionX: { lg: '800px', md: '650px', sm: '550px', xl: '1000px', xs: '350px' },
        backgroundPositionY: { lg: '-600px', md: '-580px', sm: '-650px', xl: '-650px', xs: '-600px' },
        height: { md: '100vh' },
        maxHeight: { md: '1000px' },
      }}
      style={{
        backgroundImage: `url(${curlWave})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '600px',
      }}
    >
      <Container sx={{ marginY: { xs: 7 } }}>
        <FlexCol sx={{ flexDirection: { md: 'row', xs: 'column' } }} alignItems="flex-start">
          <FlexCol maxWidth="700px" flexGrow={100} flexBasis={100} alignItems="flex-start" alignContent="flex-start">
            <Typography flexWrap="wrap" variant="subtitle1" gutterBottom={true}>
              PRICES
            </Typography>
            <Typography flexWrap="wrap" variant="h3" fontWeight={700} gutterBottom={true} paddingBottom={2}>
              Current prices of XYO Token
            </Typography>
            <Typography flexWrap="wrap" variant="body1" gutterBottom={true}>
              The current price of XYO can be viewed on all the exchanges that support XYO. Additionally, aggregate
              pricing can be viewed on these sites.
            </Typography>
          </FlexCol>
          <FlexCol
            alignItems="flex-start"
            marginY={2}
            flexGrow={100}
            flexBasis={100}
            sx={{ flexDirection: { xs: 'row' } }}
            flexWrap="wrap"
            alignContent="flex-start"
            padding={3}
            maxWidth="700px"
          >
            <ButtonEx
              size={breakpoint === 'xs' ? 'medium' : 'large'}
              margin={1}
              href="https://coinmarketcap.com/currencies/xyo/"
              target="_blank"
              variant="contained"
              style={{ backgroundColor: '#ffffff' }}
              aria-label="Coin Market Cap"
            >
              <img height={24} src={CoinMarketCapLogo} alt="Coin Market Cap" />
            </ButtonEx>
            <ButtonEx
              size={breakpoint === 'xs' ? 'medium' : 'large'}
              margin={1}
              href="https://www.coingecko.com/en/coins/xyo-network"
              target="_blank"
              style={{ backgroundColor: '#ffffff' }}
              variant="contained"
              aria-label="Coin Gecko"
            >
              <img height={24} src={CoingeckoLogo} alt="Coin Gecko" />
            </ButtonEx>
            <ButtonEx
              size={breakpoint === 'xs' ? 'medium' : 'large'}
              margin={1}
              href="https://crypto.com/price/xyo"
              target="_blank"
              style={{ backgroundColor: '#ffffff' }}
              variant="contained"
              aria-label="Crypto.com"
            >
              <img height={24} src={CryptoComLogo} alt="Crypto.com" />
            </ButtonEx>
            <ButtonEx
              size={breakpoint === 'xs' ? 'medium' : 'large'}
              margin={1}
              href="https://coinranking.com/coin/xyonetwork-xyo"
              target="_blank"
              style={{ backgroundColor: '#ffffff' }}
              variant="contained"
              aria-label="Coin Ranking"
            >
              <img height={24} src={CoinRankingLogo} alt="Coin Ranking" />
            </ButtonEx>
          </FlexCol>
        </FlexCol>
      </Container>
    </FlexRow>
  )
}

export default Price
