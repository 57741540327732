import { AppBarEx, AppBarExProps } from '@xyo-network/sdk-xyo-react-js'

import ContextToolbar from './ContextToolbar'
import SystemToolBar from './SystemToolbar'
const Header: React.FC<AppBarExProps> = ({ contextToolbar, systemToolbar, ...props }) => {
  return (
    <AppBarEx
      container="lg"
      contextToolbar={contextToolbar ?? <ContextToolbar />}
      systemToolbar={systemToolbar ?? <SystemToolBar />}
      onClick={(event) => event.stopPropagation()}
      {...props}
    />
  )
}

export default Header
