import { Box, useScrollTrigger, useTheme, Zoom, ZoomProps } from '@mui/material'
import React from 'react'

const ScrollToTopButton: React.FC<ZoomProps> = (props) => {
  const { children, ...rootProps } = props
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  })

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector('#back-to-top-anchor')

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  const theme = useTheme()

  return (
    <Zoom in={trigger} {...rootProps}>
      <Box
        zIndex={10}
        position="fixed"
        right={theme.spacing(2)}
        bottom={theme.spacing(2)}
        onClick={handleClick}
        role="presentation"
      >
        {children}
      </Box>
    </Zoom>
  )
}

export default ScrollToTopButton
