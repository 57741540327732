import UserEventId from './UserEventId'
import UserEvents from './UserEvents'

//we call this outside the hook to force initialization on page load
const instance = UserEvents.get()

const useUserEvents = () => {
  return instance
}

export { UserEventId, UserEvents, useUserEvents }
