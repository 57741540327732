enum UserEventId {
  AddToCart = 'add-to-cart',
  AddressInfoComplete = 'address-info-complete',
  ClickToDownloadApp = 'click-to-download-app',
  ContactInfoComplete = 'contact-info-complete',
  PaymentInfoComplete = 'payment-info-complete',
  TestStarted = 'test-started',
  TransactionAttempted = 'transaction-attempted',
  TransactionCompleted = 'transaction-completed',
  TransactionFailed = 'transaction-failed',
  UpsellAccepted = 'upsell-completed',
  UpsellFailed = 'upsell-failed',
  UpsellSucceeded = 'upsell-succeeded',
  UpsellUnavailable = 'upsell-unavailable',
  UpsellViewed = 'upsell-viewed', //specifically for the direct app download funnel
  UserClick = 'user-click',
}

export default UserEventId
