import { Container, Link, Typography, useTheme } from '@mui/material'
import { FlexBoxProps, FlexCol, FlexGrowRow, FlexRow } from '@xyo-network/sdk-xyo-react-js'

import { coinmarketcapGraph } from './img'

const Hero: React.FC<FlexBoxProps> = (props) => {
  const theme = useTheme()

  return (
    <Container>
      <FlexGrowRow
        width="100%"
        minHeight={100}
        paddingY={4}
        sx={{ height: { md: '100vh' }, maxHeight: { md: '1000px' } }}
        color={theme.palette.primary.dark}
      >
        <FlexCol alignItems="flex-start" marginY={2} {...props}>
          <Typography flexWrap="wrap" variant="subtitle1" gutterBottom={true}>
            TOKEN
          </Typography>
          <Typography flexWrap="wrap" variant="h3" fontWeight={700} marginY={2}>
            Meet the Token that powers XYO Network
          </Typography>
          <Typography flexWrap="wrap" variant="body1" marginY={2}>
            With the recent growth of XYO Token, the ability for you to buy, sell, view, analyze, and participate in the
            XYO Token market has grown along with it.
          </Typography>
        </FlexCol>
        <FlexCol alignItems="stretch" marginY={2} {...props}>
          <FlexRow>
            <img src={coinmarketcapGraph} width="80%" />
          </FlexRow>
          <FlexRow>
            <Typography variant="subtitle2">
              Graph as of 9/16/2021{' '}
              <Link href="https://coinmarketcap.com/currencies/xyo/" target="_blank">
                [Live Graph]
              </Link>
            </Typography>
          </FlexRow>
        </FlexCol>
      </FlexGrowRow>
    </Container>
  )
}

export default Hero
