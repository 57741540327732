import { Container, Divider, Typography, useTheme } from '@mui/material'
import { ButtonEx, FlexBoxProps, FlexCol, FlexRow } from '@xyo-network/sdk-xyo-react-js'

import { EtherscanLogo } from '../../img'

const Contract: React.FC<FlexBoxProps> = (props) => {
  const theme = useTheme()
  return (
    <FlexRow bgcolor={theme.palette.secondary.main} color={theme.palette.secondary.contrastText} {...props}>
      <Container>
        <FlexCol justifyContent="flex-start" alignItems="flex-start" margin={2} marginX={4}>
          <Typography flexWrap="wrap" variant="subtitle1" margin={2}>
            Contract
          </Typography>
          <Typography flexWrap="wrap" variant="h3" fontWeight={700} margin={2}>
            XYO Token ERC20 Contract
          </Typography>
          <Divider style={{ width: 120 }} />
          <Typography flexWrap="wrap" variant="body1" margin={2}>
            XYO Token is a ERC20 Token. You are able to view the source code for the smart contract that manages the
            token data on various sites.
          </Typography>
          <FlexRow>
            <ButtonEx
              margin={1}
              href="https://etherscan.io/token/0x55296f69f40ea6d20e478533c15a6b08b654e758#readContract"
              target="_blank"
              color="inherit"
              variant="contained"
              aria-label="Etherscan"
            >
              <img height={24} src={EtherscanLogo} alt="Etherscan" />
            </ButtonEx>
          </FlexRow>
        </FlexCol>
      </Container>
    </FlexRow>
  )
}

export default Contract
