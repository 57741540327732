import { RefObject, useEffect, useRef } from 'react'

import { useUserEvents } from '../../hooks'
import BasePage from '../BasePage'
import Contract from './Contract'
import Exchanges from './Exchanges'
import Hero from './Hero'
import Price from './Price'
import Wallets from './Wallets'

const Page: React.FC = () => {
  const userEvents = useUserEvents()

  useEffect(() => {
    userEvents.viewContent({ name: 'Home', path: document.location.href })
  }, [userEvents])

  const anchors: Record<string, RefObject<HTMLDivElement>> = {
    contract: useRef<HTMLDivElement>(null),
    exchanges: useRef<HTMLDivElement>(null),
    price: useRef<HTMLDivElement>(null),
    wallets: useRef<HTMLDivElement>(null),
  }

  const anchor = anchors[location.hash.slice(1)]

  useEffect(() => {
    if (anchor?.current) {
      anchor?.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [anchor])

  return (
    <BasePage>
      <Hero marginY={2} />
      <div ref={anchors.exchanges} />
      <Exchanges id="exchanges" />
      <div ref={anchors.price} />
      <Price id="price" />
      <div ref={anchors.wallets} />
      <Wallets id="wallets" />
      <div ref={anchors.contract} />
      <Contract paddingY={4} id="contract" />
    </BasePage>
  )
}

export default Page
