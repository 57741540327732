import { KeyboardArrowUp } from '@mui/icons-material'
import { Fab, Typography } from '@mui/material'
import {
  BasePage,
  BasePageProps,
  CookieConsent,
  FlexCol,
  FlexGrowCol,
  FlexRow,
  InvertableThemeProvider,
  isLocalhost,
} from '@xyo-network/sdk-xyo-react-js'
import React, { PropsWithChildren, ReactNode } from 'react'

import { useSettings } from '../Contexts'
import Footer from '../Footer'
import { HeaderAppBar } from '../Header'
import RedirectEx from '../RedirectEx'
import ScrollToTopButton from './ScrollToTopButton'

interface XyBasePageProps extends BasePageProps {
  devOnly?: string | boolean
  devOnlyTo?: string | boolean
  localAsDev?: boolean
  scrollToTopButton?: ReactNode
}

const XyBasePage: React.FC<PropsWithChildren<XyBasePageProps>> = ({
  devOnly = false,
  devOnlyTo = false,
  localAsDev = false,
  scrollToTopButton,
  children,
  ...props
}) => {
  const { developerMode } = useSettings()

  const devRequirementPassed = !(devOnly || devOnlyTo) || developerMode || (localAsDev && isLocalhost)

  return (
    <BasePage
      appBar={
        <InvertableThemeProvider>
          <HeaderAppBar />
        </InvertableThemeProvider>
      }
      appFooter={
        <InvertableThemeProvider>
          <FlexCol alignItems="stretch">
            <Footer />
          </FlexCol>
        </InvertableThemeProvider>
      }
      cookieConsent={<CookieConsent />}
      {...props}
    >
      <FlexGrowCol alignItems="stretch">
        {devOnly && devRequirementPassed ? (
          <FlexRow margin={1}>
            <Typography variant="body1">
              Important: This page is a Developer Only page. It is possible that some information may not be correct.
            </Typography>
          </FlexRow>
        ) : null}
        {devOnly && !devRequirementPassed ? <RedirectEx to="/" /> : null}
        {children}
      </FlexGrowCol>
      {scrollToTopButton ?? (
        <ScrollToTopButton>
          <Fab aria-label="scroll back to top" color="secondary" size="small">
            <KeyboardArrowUp />
          </Fab>
        </ScrollToTopButton>
      )}
    </BasePage>
  )
}

export default XyBasePage
