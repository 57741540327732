import { UserClickFields, ViewContentFields } from '@xylabs/pixel'
import { XyCustomEvent } from '@xyo-network/sdk-xyo-react-js'

class XyEvents<T extends Record<string, unknown>> {
  public userClick() {
    return new XyCustomEvent<UserClickFields | T>('UserClick')
  }

  public viewContent() {
    return new XyCustomEvent<ViewContentFields | T>('ViewContent')
  }
}

export default XyEvents
