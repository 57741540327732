import {
  FunnelStartedFields,
  TestStartedFields,
  UserClickFields,
  UserEventHandler,
  ViewContentFields,
} from '@xylabs/pixel'

import CustomProperties from './CustomProperties'
import CoinEvents from './Xy'

class XyEventHandler<T> extends UserEventHandler<T> {
  testStarted(_fields: T | TestStartedFields): Promise<void> {
    throw new Error('Method not implemented.')
  }
  funnelStarted(_fields: T | FunnelStartedFields): Promise<void> {
    throw new Error('Method not implemented.')
  }
  private events = new CoinEvents<CustomProperties>()

  async userClick(fields: UserClickFields) {
    await this.events.userClick().send(fields)
  }

  async viewContent(fields: ViewContentFields) {
    await this.events.viewContent().send(fields)
  }
}

export default XyEventHandler
