import { Toolbar } from '@mui/material'
import { ButtonEx } from '@xyo-network/sdk-xyo-react-js'

const SystemToolBar: React.FC = () => {
  return (
    <Toolbar disableGutters>
      <ButtonEx marginX={1} sx={{ display: { md: 'inherit', xs: 'none' } }} to="/#exchanges">
        Exchanges
      </ButtonEx>
      <ButtonEx marginX={1} sx={{ display: { md: 'inherit', xs: 'none' } }} to="/#price">
        Price
      </ButtonEx>
      <ButtonEx marginX={1} sx={{ display: { md: 'inherit', xs: 'none' } }} to="/#wallets">
        Wallets
      </ButtonEx>
      <ButtonEx marginX={1} sx={{ display: { md: 'inherit', xs: 'none' } }} to="/#contract">
        Contract
      </ButtonEx>
      <ButtonEx marginX={1} to="/#exchanges" variant="contained" color="primary">
        Buy XYO
      </ButtonEx>
    </Toolbar>
  )
}

export default SystemToolBar
