import { Container, Typography, useTheme } from '@mui/material'
import { ButtonEx, FlexBoxProps, FlexCol, FlexRow, useBreakpoint } from '@xyo-network/sdk-xyo-react-js'

import { CoinbaseLogo, CoinbaseProLogo, GateIoLogo, KucoinLogo, ProbitLogo, Uniswap3Logo } from '../../img'
import { gradientWave } from './img'

const Exchanges: React.FC<FlexBoxProps> = (props) => {
  const theme = useTheme()
  const breakpoint = useBreakpoint()
  return (
    <FlexRow
      bgcolor={theme.palette.secondary.light}
      color={theme.palette.secondary.dark}
      {...props}
      sx={{
        backgroundPositionX: { lg: '-400px', md: '-400px', sm: '-600px', xl: '-400px', xs: '-400px' },
        backgroundPositionY: { lg: '-100px', md: '-100px', sm: '150px', xl: '-100px', xs: '300px' },
        height: { md: '100vh' },
        maxHeight: { md: '1000px' },
      }}
      style={{
        backgroundImage: `url(${gradientWave})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '800px',
      }}
    >
      <Container sx={{ marginY: { xs: 5 } }}>
        <FlexCol sx={{ flexDirection: { md: 'row', xs: 'column-reverse' } }}>
          <FlexCol
            alignItems="flex-start"
            flexGrow={100}
            flexBasis={100}
            sx={{ flexDirection: { xs: 'row' } }}
            flexWrap="wrap"
            alignContent="flex-start"
            padding={3}
          >
            <ButtonEx
              size={breakpoint === 'xs' ? 'medium' : 'large'}
              margin={1}
              href="https://www.coinbase.com/price/xyo"
              target="_blank"
              style={{ backgroundColor: '#ffffff' }}
              variant="contained"
              aria-label="Coinbase"
            >
              <img height={24} src={CoinbaseLogo} alt="Coinbase" />
            </ButtonEx>
            <ButtonEx
              size={breakpoint === 'xs' ? 'medium' : 'large'}
              margin={1}
              href="https://pro.coinbase.com/trade/XYO-USD"
              target="_blank"
              style={{ backgroundColor: '#ffffff' }}
              variant="contained"
              aria-label="Coinbase Pro"
            >
              <img height={24} src={CoinbaseProLogo} alt="Coinbase Pro" />
            </ButtonEx>
            <ButtonEx
              size={breakpoint === 'xs' ? 'medium' : 'large'}
              margin={1}
              href="https://info.uniswap.org/#/tokens/0x55296f69f40ea6d20e478533c15a6b08b654e758"
              target="_blank"
              style={{ backgroundColor: '#ffffff' }}
              variant="contained"
              aria-label="Uniswap 3"
            >
              <img height={24} src={Uniswap3Logo} alt="Uniswap 3" />
            </ButtonEx>
            <ButtonEx
              size={breakpoint === 'xs' ? 'medium' : 'large'}
              margin={1}
              href="https://trade.kucoin.com/XYO-USDT"
              target="_blank"
              style={{ backgroundColor: '#ffffff' }}
              variant="contained"
              aria-label="Kucoin"
            >
              <img height={24} src={KucoinLogo} alt="Kucoin" />
            </ButtonEx>
            <ButtonEx
              size={breakpoint === 'xs' ? 'medium' : 'large'}
              margin={1}
              href="https://www.gate.io/trade/xyo_usdt"
              target="_blank"
              style={{ backgroundColor: '#ffffff' }}
              variant="contained"
              aria-label="Gate.io"
            >
              <img height={24} src={GateIoLogo} alt="Gate.io" />
            </ButtonEx>
            <ButtonEx
              size={breakpoint === 'xs' ? 'medium' : 'large'}
              margin={1}
              href="https://www.probit.com/app/exchange/XYO-USDT"
              target="_blank"
              style={{ backgroundColor: '#ffffff' }}
              variant="contained"
              aria-label="Probit"
            >
              <img height={24} src={ProbitLogo} alt="Probit" />
            </ButtonEx>
          </FlexCol>
          <FlexCol alignItems="flex-start" flexGrow={100} flexBasis={100}>
            <Typography flexWrap="wrap" variant="subtitle1" gutterBottom={true}>
              EXCHANGES
            </Typography>
            <Typography flexWrap="wrap" variant="h3" fontWeight={700} gutterBottom={true} paddingBottom={2}>
              Exchanges that support XYO Token Trading
            </Typography>
            <Typography flexWrap="wrap" variant="body1" gutterBottom={true}>
              When it comes to trading XYO, you’ve got plenty of options! XYO was recently listed on Coinbase and
              Coinbase Pro, which serves as a great option for those with an account or if you’re just beginning to
              learn about cryptocurrency. Click an exchange to get started.
            </Typography>
          </FlexCol>
        </FlexCol>
      </Container>
    </FlexRow>
  )
}

export default Exchanges
