import { FacebookCustomEvent, FacebookStandardEvents } from '@xyo-network/sdk-xyo-react-js'

export interface FacebookUserClick extends Record<string, unknown> {
  elementName: string
  elementType: string
}

class FacebookEvents<T extends Record<string, unknown>> extends FacebookStandardEvents<T> {
  public userClick() {
    return new FacebookCustomEvent<FacebookUserClick | T>('UserClick')
  }
}

export default FacebookEvents
