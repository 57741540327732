import React from 'react'

interface Props {
  darkMode?: boolean
  developerMode?: boolean
  enableDarkMode?: (enabled: boolean) => void
  enableDeveloperMode?: (show: boolean) => void
}

const SettingsContext = React.createContext<Props>({})
export default SettingsContext
