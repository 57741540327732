import { Toolbar } from '@mui/material'
import { FlexRow, LinkToEx } from '@xyo-network/sdk-xyo-react-js'

const ContextToolbar: React.FC = () => {
  return (
    <Toolbar disableGutters>
      <LinkToEx to="/">
        <FlexRow>
          <img src="https://cdn.xy.company/img/brand/XYO/Downloadable/XYO_full_colored.svg" height={48} />
        </FlexRow>
      </LinkToEx>
    </Toolbar>
  )
}

export default ContextToolbar
